<template>
  <div>
    <h1>Welcome, {{ userInfo.first_name }}</h1>
    <div class="quick-actions">
      <h3>Quick Actions</h3>
      <div class="actions">
        <div
          class="actions__item"
          v-for="i in actions"
          :key="i.id"
          :style="{
            backgroundImage: `url(${require(`@/assets/images/dashboard/${i.image}`)}`,
          }"
        >
          <BaseButton
            class="button--admin button--full-width button--black"
            :text="i.button"
            @click="selectAction(i)"
          ></BaseButton>
        </div>
      </div>
    </div>
    <Art
      v-if="modal.art"
      :modal="modal.art"
      @hideModal="modal.art = false"
      @createArt="createArt"
    />
    <Exhibition
      v-if="modal.exhibition"
      :modal="modal.exhibition"
      @hideModal="modal.exhibition = false"
      @createExhibition="createExhibition"
    />
    <Event
      v-if="modal.event"
      :modal="modal.event"
      @hideModal="modal.event = false"
      @createEvent="createEvent"
    />
    <Post
      v-if="modal.blog"
      :modal="modal.blog"
      @hideModal="modal.blog = false"
      @createItem="createPost"
    />
  </div>
</template>
<script>
import BaseButton from "@/components/buttons/BaseButton.vue";
import PopUp from "@/components/PopUp.vue";
import Art from "@/views/admin/collection/artworks/Art.vue";
import Event from "@/views/admin/events/events/Event.vue";
import Exhibition from "@/views/admin/events/exhibitions/Exhibition.vue";
import Post from "@/views/admin/arttextstyle/arttextstyle/Post.vue";
import { mapGetters, mapActions } from "vuex";
export default {
  components: { BaseButton, PopUp, Art, Exhibition, Event, Post },
  data() {
    return {
      actions: [
        { id: 1, button: "add art", action: "open", key: "art", image: "art.png" },
        {
          id: 2,
          button: "add exhibition",
          action: "open",
          key: "exhibition",
          image: "exhibition.png",
        },
        { id: 3, button: "add event", action: "open", key: "event", image: "event.png" },
        { id: 4, button: "add blog post", action: "open", key: "blog", image: "post.png" },
      ],
      modal: {
        art: false,
        exhibition: false,
        event: false,
        blog: false,
      },
    };
  },
  async created() {
    await this.apiGetExhibitionTypes({ limit: "" });
  },
  computed: {
    ...mapGetters("user", {
      userInfo: "getUserInfo",
    }),
  },
  methods: {
    ...mapActions("general", ["apiGetExhibitionTypes"]),

    ...mapActions("artworks", ["apiPostArt"]),
    ...mapActions("exhibitions", ["apiPostExhibition"]),
    ...mapActions("events", ["apiPostEvent"]),
    ...mapActions("arttextstyle", ["apiPostArttextstyle"]),
    selectAction(item) {
      switch (item.action) {
        case "open": {
          this.modal[item.key] = true;
          break;
        }
      }
    },
    async createArt({ data }) {
      let result = await this.apiPostArt(data);
      if (result) {
        this.modal.art = false;
      }
    },
    async createExhibition({ data }) {
      let result = await this.apiPostExhibition(data);
      if (result) {
        this.modal.exhibition = false;
      }
    },
    async createEvent({ data }) {
      let result = await this.apiPostEvent(data);
      if (result) {
        this.modal = false;
      }
    },
    async createPost({ data }) {
      let result = await this.apiPostArttextstyle(data);
      if (result) {
        this.modal.blog = false;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
h1 {
  margin-bottom: 5.5rem;
  margin-top: 2.5rem;
  color: $black;
}
.quick-actions {
  padding: 25px 30px 15px;
  border-radius: 16px;
  background-color: $white;
  border: 0.5px solid $lightGrey;
  h3 {
    width: 100%;
    @include type($fs: 2.1rem);
    color: $lightBlack;
    margin-bottom: 25px;
  }
}
.actions {
  display: flex;
  flex-wrap: wrap;
  width: calc(100% + 20px);
  margin-left: -10px;

  &__item {
    position: relative;
    display: flex;
    align-items: flex-end;
    width: calc((100% - 80px) / 4);
    height: 50rem;
    margin: 0 10px 20px;
    padding: 17px 13px;
    border-radius: 10px;
    background-size: cover;
    background-position: center;
    @media only screen and (max-width: $lg) {
      width: calc((100% - 8.4rem) / 2);
      height: 45rem;
    }
    @media only screen and (max-width: $md) {
      width: 100%;
      height: 40rem;
    }
  }
}
</style>